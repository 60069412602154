export default {
  meta_title: 'Quantum Programming Software — PennyLane',
  meta_description:
    'Built by researchers for research, PennyLane is the definitive open-source Python framework for quantum machine learning, quantum chemistry, and quantum computing.',
  hero_section: {
    title: 'Discover new ideas faster.',
    description: `The definitive open-source Python framework for quantum programming. Built by researchers, for research.`,
    subtitles: [
      {
        text: 'Program',
        link_text: 'quantum computers',
        link: '/qml/quantum-computing',
      },
      {
        text: 'Integrate with',
        link_text: 'machine learning',
        link: '/qml/quantum-machine-learning',
      },
      {
        text: 'Master',
        link_text: 'quantum chemistry',
        link: '/qml/quantum-chemistry',
      },
    ],
    left_button: {
      text: 'Install PennyLane',
      link: '/install',
      icon: '',
    },
    right_button: {
      text: 'Explore demos',
      link: '/qml/demonstrations/',
      icon: '',
    },
  },
  spotlight_section: {
    announcements: [
      {
      title: 'SURVEY CLOSES OCTOBER 16, 2024',
      description:
        'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
      image_right_aligned: false,
      alt: '',
      links: [
        // {
        //   link_text: 'Learn more', // Please restrict to 30 characters including spaces
        //   link: '/codebook/',
        // },
      {
          link_text: 'Take the survey now!', // Please restrict to 30 characters including spaces
           link: 'https://www.surveymonkey.com/r/qosssurvey24',
      },
      ],
      colours: {
        border: '#CC00CC',
        background: '#FDFAFD',
        title: '#550055',
        description: '#012343',
        link: '#550055',
      },
      },
      {
        title: 'NEW PENNYLANE CODEBOOK MODULES',
        description:
          'Explore Hamiltonian Time Evolution and control the Time Evolution of qubits. Want more? Control your qubits in Distance Measures.',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Check it out!', // Please restrict to 30 characters including spaces
            link: '/codebook/',
          },
         // {
         //   link_text: 'Check out the blog post', // Please restrict to 30 characters including spaces
         //   link: '/blog/2024/05/pennylane_codebook_makeover/',
        //  },
        ],
        colours: {
          border: '#0274DE',
          background: '#F7FAFC',
          title: '#0271D9',
          description: '#002445',
          link: '#0271D9',
        },
      },      
    ],
  },
  partners_section: {
    title: 'Our partners',
    // NOTE: the logos are shown in the order below
    logos: [
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/xanadu.png',
        alt: 'Xanadu',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/AWS.png',
        alt: 'AWS',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/Agnostiq.png',
        alt: 'Agnostiq',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/AQT.png',
        alt: 'AQT',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/Covestro.png',
        alt: 'Covestro',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/CDL.png',
        alt: 'Creative Destruction Lab',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/Google.png',
        alt: 'Google',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/ibmquantum.png',
        alt: 'IBM Quantum',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/NVIDIA.png',
        alt: 'NVIDIA',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/Rigetti.png',
        alt: 'Rigetti',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/unitaryfund.png',
        alt: 'Unitary Fund',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/ionq.png',
        alt: 'IonQ',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/microsoft.png',
        alt: 'Microsoft',
      },
      {
        src: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/hero_section/partners/Multiverse.png',
        alt: 'Multiverse',
      },
    ],
  },
  call_to_action_section: [
    {
      id: 'minimize-time-to-research',
      title: 'Minimize time-to-research.',
      description:
        'PennyLane is unopinionated where it matters. With a functional interface, seamless integration with the scientific ecosystem, and modular building blocks, we get out of the way to help you easily build cutting-edge quantum algorithms.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/call_to_action_section/minimize-time.svg',
      image_right_aligned: false,
      alt: 'Condensed quantum information',
      links: [
        {
          link_text: 'Using PennyLane',
          link: 'https://docs.pennylane.ai/en/stable/introduction/pennylane.html',
        },
      ],
    },
    {
      id: 'accelerated-performance',
      title: 'Accelerated performance.',
      description:
        'Scale up your research. Use the high-performance Lightning simulator — backed by NVIDIA cuQuantum — on GPUs and the cloud. But it goes beyond; PennyLane will automatically use the best computational methods for your workflow.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/second_call_to_action/performance.svg',
      image_right_aligned: true,
      alt: 'A speedy quantum rollercoaster',
      links: [
        {
          link_text: 'Learn how',
          link: 'https://pennylane.ai/performance/',
        },
      ],
    },
    {
      id: 'get-up-to-speed-quickly',
      title: 'Get up to speed quickly.',
      description:
        'Quantum computing can be complex — PennyLane simplifies it. Explore our extensive library of demonstrations and in-depth documentation to advance your expertise in quantum computing, quantum machine learning, and quantum chemistry. Discover how PennyLane can enhance your research and integrate seamlessly into your projects.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/call_to_action_section/get-started-faster.svg',
      image_right_aligned: false,
      alt: 'Quantum map',
      links: [
        {
          link_text: 'Learn about quantum',
          link: '/qml/',
        },
        {
          link_text: 'Teach quantum',
          link: '/education',
        },
      ],
    },
  ],
  pennylane_used_section: {
    title: `See how PennyLane is used`,
    partners: [
      {
        logo: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/pennylane_used_section/covestro.png`,
        logoAltText: `Covestro`,
        description: `See how Covestro uses PennyLane in their quantum chemistry research to simulate VQE gate fabrics.`,
        link: `https://iopscience.iop.org/article/10.1088/1367-2630/ac2cb3`,
      },
      {
        logo: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/pennylane_used_section/AWS.png`,
        logoAltText: `Amazon`,
        description: `Run PennyLane algorithms on quantum hardware with Amazon Braket.`,
        link: `https://github.com/aws/amazon-braket-examples/tree/main/examples/pennylane`,
      },
      {
        logo: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/pennylane_used_section/agnostiq.png`,
        logoAltText: `Agnostiq`,
        description: `Learn how Covalent — built with PennyLane for distributed quantum and HPC — supports QML research.`,
        link: `/qml/demos/tutorial_univariate_qvr`,
      },
      {
        logo: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/pennylane_used_section/unitaryfund.png`,
        logoAltText: `Unitary Fund`,
        description: `See how Mitiq — a toolkit for minimizing noise by the Unitary Fund — integrates with PennyLane`,
        link: `/blog/2021/11/error-mitigation-with-mitiq-and-pennylane/`,
      },
      {
        logo: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/pennylane_used_section/nvidia.png`,
        logoAltText: `NVIDIA`,
        description: `Learn how NVIDIA works with PennyLane to enable GPU simulations at scale.`,
        link: `/blog/2022/07/lightning-fast-simulations-with-pennylane-and-the-nvidia-cuquantum-sdk/`,
      },
    ],
  },
  second_call_to_action: [
    {
      id: 'community-first-software-development',
      title: 'Community-first software development.',
      description:
        'PennyLane is community-driven, built to support your research. With open development on GitHub and direct access to our team, your feedback shapes the roadmap. Join us and help shape the future of quantum research with PennyLane.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/call_to_action_section/community-first.svg',
      image_right_aligned: true,
      alt: 'People looking at the stream on a laptop',
      links: [
        {
          link_text: 'Get involved',
          link: '/get-involved/',
        },
      ],
    },
    {
      id: 'everything-differentiable',
      title: 'Everything differentiable.',
      description:
        'PennyLane pioneers a new paradigm — quantum differentiable programming. Everything is trainable, even when using quantum hardware. Don’t just train parameters; train the entire structure of your quantum model.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/second_call_to_action/differentiable.svg',
      image_right_aligned: false,
      alt: 'Quantum differentiable programming equations',
      links: [
        {
          link_text: 'Quantum differentiable programming',
          link: '/features',
        },
      ],
    },
  ],
  getting_started_section: {
    tutorialsTitle: `Don't know where to start?`,
    tutorialsSubtitle: `Here are a few beginner-friendly resources to help you get started`,
    tutorials: [
      {
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/getting_started_section/learn.png`,
        title: `Learn`,
        description: `Learn and master the concepts behind quantum computing.`,
        url: `/qml/`,
      },
      {
        image: `https://assets.cloud.pennylane.ai/pennylane_website/icons/codebook_icon.png`,
        title: `Codebook`,
        description: `Use PennyLane to explore various quantum computing topics in the PennyLane Codebook.`,
        url: `/codebook/`,
      },
      {
        image: `https://assets.cloud.pennylane.ai/pennylane_website/pages/landing_page/getting_started_section/documentation.png`,
        title: `Documentation`,
        description: `Get started with PennyLane using our quickstart guides, learn how to develop a plugin, and browse the full API.`,
        url: `https://docs.pennylane.ai/en/stable/`,
      },
    ],
    videosTitle: `Videos to help you get started`,
    videosLinkText: `See all videos`,
    videosLinkURL: `/qml/videos`,
  },
  building_with_pennylane: {
    title: `Start building with PennyLane`,
    buttons: [
      {
        text: 'Install PennyLane',
        link: '/install',
      },
      {
        text: 'Explore demos',
        link: '/qml/demonstrations',
      },
    ],
  },
}
